import styled from '@emotion/styled'
import { graphql } from 'gatsby'
import { GatsbyImage, getImage, withArtDirection } from 'gatsby-plugin-image'
import React from 'react'

import Layout from '../components/Layout'
import Link from '../components/Link'
import ElementExplore from '../components/red-black-explore'
import SEO from '../components/seo'
import useLocalisation from '../helper/useLocalisation'

const ExploreDiv = styled.div`
  text-align: center;
  font-family: 'aw-conqueror-didot';
  color: #212121;
  font-size: 42px;
  line-height: 1.2;
  padding-top: 32px;
  padding-bottom: 16px;
  @media (max-width: 763px) {
    font-size: 28px;
  }
`
const Desktop = styled.span`
  @media (max-width: 1050px) {
    display: none;
  }
`
const Mobile = styled.span`
  display: none;
  @media (max-width: 800px) {
    display: inline;
  }
`

const SectionContainer = styled.div`
  display: flex;
  align-items: stretch;
  background-color: rgb(var(--${(props) => props.color}));
  flex-direction: ${(props) => (props.reverse ? 'row-reverse' : 'row')};
  ${({ colPadding }) => colPadding && 'padding: 128px 0;'}
  @media (max-width: 1025px) {
    ${(props) => (props.mobilePadding ? 'padding: 32px 0px;' : '')};
    flex-direction: ${(props) =>
      props.reverseMobile ? 'column-reverse' : 'column'};
  }
`

const HeaderImage = styled(GatsbyImage)`
  width: 50%;
  ${(props) => (props.padding ? 'margin: 8%;width: 34%;' : '')}
  ${(props) => (props.noTopPadding ? 'margin: 0 8%;width: 34%;' : '')}
  ${(props) =>
    props.sidePadding ? 'margin: 5%;width: 40%;margin-bottom: 0;' : ''}
  @media (max-width: 1025px) {
    width: 100%;
    ${(props) => (props.padding ? 'width: 90%;margin: 5%;' : '')}
    ${(props) => (props.noMobile ? 'display: none;' : '')}
    ${(props) =>
      props.sidePadding ? 'margin: 5%;width: 90%;margin-bottom: 0;' : ''}
  }
`

const CopyWidthContainer = styled.div`
  @media (min-width: 1025px) {
    max-width: 450px;
    ${(props) => props.left && 'text-align: left;'}
  }
`
const SectionCopy = styled.div`
  width: ${(props) => (props.short ? '45%;' : '50%;')};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  @media (max-width: 1025px) {
    width: 100%;
    text-align: left;
    padding: 32px 32px;
    z-index: 2;
    ${(props) => (props.left ? 'align-items: flex-start;' : '')};
    ${(props) => (props.lower ? 'padding: 0 32px;' : '')};
    ${(props) => (props.noTopPadding ? 'padding-top: 0;' : '')}
    ${(props) =>
      props.center ? 'text-align: center; align-items: center;' : ''}
  }
`
const SmallSubHeading = styled.div`
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 2.25;
  letter-spacing: 0.71px;
  color: rgb(var(--${(props) => props.color}));
  padding: 16px 0;
  @media (max-width: 1025px) {
    padding: 0;
  }
`
const CopyHeading = styled.div`
  font-family: 'aw-conqueror-didot';
  font-size: 35px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.18;
  letter-spacing: -1.11px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  padding: 16px 0;
  @media (max-width: 1025px) {
    font-size: 25px;
    font-weight: 500;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  }
`

const CopyBody = styled.div`
  margin: 16px 0;
  font-size: 21px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.33;
  letter-spacing: 0.12px;
  color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
  @media (max-width: 1025px) {
    font-size: 13px;
    font-weight: normal;
    font-stretch: normal;
    font-style: normal;
    line-height: 1.38;
    letter-spacing: 0.07px;
    color: rgb(var(--${(props) => (props.color ? props.color : 'black')}));
    margin-right: 48px;
    margin: 0;
  }
`

const ColourRangePage = ({ data }) => {
  return (
    <Layout dreamscapes={false}>
      <link rel="stylesheet" href="https://use.typekit.net/cil3vci.css" />
      <SEO
        title={data.SEO.title.value}
        description={data.SEO.description.value}
        cannonical_url={data.SEO.cannonical_url.value}
        og_image={
          data.SEO?.TEST_banner_image?.value?.childImageSharp?.gatsbyImageData
            ?.srcWebp
        }
      />
      <GatsbyImage
        style={{ height: 'calc(100vh - 125px)' }}
        objectFit="cover"
        objectPosition="bottom center"
        image={getImage(data.RedBlackBannerNew)}
      />
      <SectionContainer reverseMobile={true} reverse={true} color="white">
        <HeaderImage padding={false} image={getImage(data.RedSet)} />
        <SectionCopy left={true}>
          <CopyWidthContainer>
            <CopyHeading color="cherry-red" me={true}>
              CHERRY RED
            </CopyHeading>
            <CopyBody color="black">
              En route and ready to go. A classic glazed red that is passionate
              and playful. Fate or coincidence your adventure is meant to be
              with Cherry Red.
            </CopyBody>
          </CopyWidthContainer>
        </SectionCopy>
      </SectionContainer>
      <SectionContainer reverseMobile={true} reverse={false} color="white">
        <HeaderImage padding={false} image={getImage(data.BlackSet)} />
        <SectionCopy left={true}>
          <CopyWidthContainer>
            <CopyHeading color="matte-black" me={true}>
              MATTE BLACK
            </CopyHeading>
            <CopyBody color="black">
              Not be glossed over. A classic matte black that is sleek and
              mysterious. What your adventure entails is up to how you play your
              cards with Matte Black.
            </CopyBody>
          </CopyWidthContainer>
        </SectionCopy>
      </SectionContainer>
      <div>
        <ExploreDiv> SHOP CHERRY RED & MATTE BLACK </ExploreDiv>
        <ElementExplore />
      </div>
    </Layout>
  )
}

export const query = graphql`
  {
    RedBlackBannerNew: file(
      relativePath: { eq: "red-black/landing/banner-1.png" }
    ) {
      childImageSharp {
        gatsbyImageData(quality: 80, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    RedSet: file(relativePath: { eq: "bundles/trunk/red/2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }
    BlackSet: file(relativePath: { eq: "bundles/trunk/bla/2.png" }) {
      childImageSharp {
        gatsbyImageData(quality: 100, placeholder: NONE, layout: FULL_WIDTH)
      }
    }

    SEO: cockpitPage(
      cannonical_url: { value: { eq: "/red-black-trunk-luggage" } }
    ) {
      title {
        value
      }
      description {
        value
      }
      cannonical_url {
        value
      }
      keywords {
        value
      }
      category {
        value
      }
      TEST_banner_image {
        value {
          childImageSharp {
            gatsbyImageData(width: 1200, height: 630, layout: FIXED)
          }
        }
      }
    }
  }
`

export default ColourRangePage
